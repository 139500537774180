import React from 'react';

import { useDate } from '@Dates/useDateHook';

export const DayGridHeader: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { getWeekdaysMin } = useDate();
  const weekdays = getWeekdaysMin();

  return (
    <thead>
      {children && (
        <tr>
          <td colSpan={7}>{children}</td>
        </tr>
      )}
      <tr
        sx={{
          borderBottomWidth: 'outlinedStrokeWeight',
          borderBottomStyle: 'solid',
          borderBottomColor: 'podBorder',
        }}
      >
        {weekdays.map((day) => (
          <th key={day}>
            <div
              sx={{
                paddingBottom: '3xs',
              }}
            >
              {day}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
